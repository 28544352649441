import { useEffect, useState, useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { useWindowSize } from 'utils/use-window-size';

const isClient = typeof window === 'object';

export const useParallax = () => {
  if (!isClient) return 0;

  const viewport = useWindowSize();
  const [inViewRef, inView] = useInView({ treshold: 0 });
  const [offset, setOffset] = useState(1);
  const ref = useRef(null);

  const onScroll = useCallback(() => {
    if (!ref.current) {
      return;
    }
    const bounds = ref.current.getBoundingClientRect();
    const center = bounds.top + bounds.height;
    const offset = Math.max(0, Math.min(1, center / (viewport.height + bounds.height)));

    setOffset(offset);
  }, [ref.current, viewport]);

  useEffect(() => {
    if (inView) {
      window.addEventListener('scroll', onScroll);
    }
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [inView]);

  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      ref.current = node;
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node);
    },
    [inViewRef]
  );

  return { offset, ref: setRefs };
};
